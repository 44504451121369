@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";
.locations{
    padding: 10px;
    @include media('<=phone') {
        padding: 0px;
    }
    .title{
        margin: 15px 0;
    }
    ul li{padding: 0;}
    h2{
        color: #244062;
        font-family: base.getFontFamily("extrabold");
        text-align: center;
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;background-color: #000;
        
    }
    h4{
        color: #2B65F6;
        font-size: 22px;
        margin: 0;
        font-family: base.getFontFamily("extrabold");
    }
    h5{
        font-size: 12px;
        color: #09294F;   
        margin-bottom: 10px;
    }
    h6{
        font-size: 14px;
        color:#244062;
        font-weight: bold;
    }
    span{
        font-size: 13px;
        color: #244062;
        font-family: base.getFontFamily("regular");
    }
    svg{
        width: 25px;
        height: 25px;
        padding: 5px;
        margin: 0px 10px 10px 0px;
        border: 1px solid #244062;
        color: #244062;
        border-radius: 25px;
    }
    strong{
        font-size: 14px;
        color: #244062;
        font-weight: bold;
    }
    p{
        color:#244062;
        font-size: 14px;
    }
    .locationGrid{
        float: left;
        width: 100%;
        overflow: hidden;
    }
    .footerContent{
        margin-bottom: 30px;
    }
        
}