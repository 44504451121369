@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.awards {
    margin-top: 100px;

    h2 {
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("extrabold");
        color: base.getColor("color");
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }

    .card {
        border: 0;
        padding: 16px;
        @include media('<=phone') {
            padding: 5px;
        }
        .cardContent{
            padding: 15px 0;
        }
        h6 {
            font-size: base.getTypography("normal");
            font-family: base.getFontFamily("bold");
            color: base.getColor("color");
            height: 6em;
            @include media('<=phone') {
                font-size: base.getMTypography("msmall");
                height: 13em;
            }
        }
    }
}