@use '../../../../styles/typography.scss' as base;
@import '../../../../styles/breakpoints.scss';

.certificateHome{
    .none{display:none;}
    .certificateMargin{margin-bottom: 50px;}
    padding: 120px 0px;
    @include media('<=phone') {
        padding: 60px 0px;
    }
    h2 {
        font-size: base.getTypography("title");
        text-align: center;
        color: base.getColor("color");
        font-family: base.getFontFamily("bold");
        padding-bottom: 15px;
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
        
    }
    .certificateList{
        padding: 25px;
        margin-top:100px;
        @include media('<=phone') {
            margin-top: 0px;
        }
        img{
            background-size: contain;
            object-fit: contain;
        }
    }
    .certificateText{
        text-align: center;
        margin-bottom: 15px;
        p{font-size: 14px;}
        h5{ font-weight: bold; color: base.getColor("color");}
        .textGrid{
            background-color: #f7f7f7; 
            padding: 15px; 
            border-radius: 15px;
            @include media('<=phone') {
                margin: 0.5rem;
            }
        }

    }
}