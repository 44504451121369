@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

body{
    padding-top: 85px;
}
.headerFixed{
    position: fixed !important;
    left: 0;
    width: 100%;
    background: #FFF;
    top: 0;
}
.header{
    flex-grow: 1;
    padding: 15px;
        @include media('<phone') {
            padding: 0;
            position: fixed;
            top: 0;
            width: 100%;
            background: #FFF;
            left: 0;
            right: 0;
        }
    .logo{
        padding: 0;
        @include media('<tablet') {
            max-width: 25%;
        }
        @include media('<phone'){
            margin: 15px 0px 15px 15px;
            max-width: 50%;
            img {
                width: 100%;                    
            }
        }
    }
}