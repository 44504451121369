@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

figure.image-style-block-align-left{
    text-align: center;
}
.aboutContent {
    margin-top: 65px;
    @include media('<=phone') {
        margin-top: 25px;
    }
    .AboutUs {
        overflow: hidden;
        figure{
            width: auto;
            display: inline-block;
            margin: 0;
            padding: 15px;
            overflow: hidden;
            box-sizing: border-box;
            float: left;
            img{
                max-width: 100%;
            }
        }
        @include media('<=phone') {
            img{
                width: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }
        p{
            text-align: justify;
            padding: 20px;
            font-size: 18px;
            line-height: 25px;
        }
        ul{
            margin: 0;
            padding: 0px 20px;
            list-style: none;
            li{
                padding: 10px 0px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    .imageTwo{
        float:right !important;
    }

        

    h1 {
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("bold");
        color: base.getColor("color");
        margin: 50px 0 16px 0;

    }

    p {
        font-size: base.getTypography("paragraph");
        font-family: base.getFontFamily("regular");
        color: base.getColor("color");
        padding-bottom: 16px;
        line-height: 23px;
        @include media('<=phone') {
            width: 100%;
            padding: 0px !important;
            float: left;
        }
    }
    @include media('<=phone') {
        ul {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}