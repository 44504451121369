@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.activitys {
    margin-top: 50px;
    .eventsImage{
        span{
            padding: 15px 0;
            display: block;
            font-weight: bold;
        }
    }
    .imageList{
        margin-bottom: 30px;
    }
    .titleTab{
        border-bottom: 1px solid #7e94af;
        width: 100%;
        float: left;
        margin-top: 25px;
        h3{
            border-bottom: 1px solid #09294F;
            font-size: base.getTypography("pageTitle");
            color: base.getColor("color");
            font-family: base.getFontFamily("bold");
            float: left;
            padding-bottom: 5px;
        }
    }
    .table {
        margin: 0px;
        max-height: 485px;
        overflow-x: auto;
        .tableRow {
            padding: 15px;
        }
        .dark {
            background-color: #eeeded;
        }
        .light {
            background-color: #f7f7f7;
        }
        .tableDetail{
            font-size: base.getTypography("normal");
        }
    }
    .affGrid {
        margin-bottom: 50px;
        padding: 15px;
        .affCard {
            overflow: visible;
            box-shadow: none;
            border-radius: 10px;
            .image {
                background-color: #FFF;
                border-radius: 15px;
                text-align: center;
                position: relative;
                img {
                    width: 100%;
                }
            }
            .affCardContent {
                padding: 0px;
                text-align: left;
                span {
                    color: #546579;
                    font-size: 10px;
                }
                p {
                    padding: 0;
                    color: #09294F;
                    font-size: base.getTypography("normal");
                }
                h5 {
                    font-size: base.getTypography("date");
                    color: #546579;
                    font-family: base.getFontFamily("regular");
                    margin:15px 0px;
                }
                h6 {
                    font-size: base.getTypography("paragraph");
                    color: base.getColor("color");
                    font-family: base.getFontFamily("bold");
                }
            }
        }
    }
}