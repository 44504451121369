@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.formModal{
    margin-top: 50px;
    position: relative;
    .closeBtn {
        background: #000;
        color: #FFF;
        padding: 10px;
        float: right;
        border-radius: 0;
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid #000;
        &:hover{
            background: #FFF;
            color: #000;
        }
        @include media('<=phone') {
            font-size: 12px;
            padding: 5px;
        }
    }
    .card{
        padding: 15px;
        box-shadow: none;
        h4{
            margin: 0;
            padding: 25px 0;
            text-align: center;
        }
        .formGrid {
            border: 1px solid #E6EBF0;
            border-radius: 15px;
            padding: 15px 15px 25px 15px;
            box-sizing: border-box;
            @include media('<=phone') {
                text-align: center;
            }

            .buttons {
                background: base.getColor("buttonBg");
                font-size: base.getTypography("listTitle");
                color: #FFF;
                width: 150px;
                height: 50px;
                border-radius: 40px;
                text-transform: capitalize;
                transition: 0.7s;
                float: right;
                &:hover {
                    background: base.getColor("hoverColor");
                }
                @include media('<=phone') {
                    margin: 0 auto;
                    float: none;
                }
            }
        }
    }
}
