@use '../../../../styles/typography.scss' as base;
@import '../../../../styles/breakpoints.scss';

.aboutHome{
    margin-top:125px;
    margin-bottom: 50px;
    @include media('<=phone') {
        margin-top: 25px;
        text-align: center;
        margin-bottom: 25px;
    }
    p{
        font-family: base.getFontFamily("regular");
        font-size:base.getTypography("paragraph");
        color: base.getColor("color");
        line-height: 26px;
        padding-right: 50px;
        padding-bottom: 25px;
        padding-top: 50px;
        @include media('<=phone') {
            padding: 10px;
            font-size: base.getTypography("small");
            line-height: 20px;
        }
    }
    h2{
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("extrabold");
        color: base.getColor("color");
        @include media('<=phone'){
            font-size: base.getMTypography("mtitle");
        }
    }
    .images{
        width: 100%;
        height: 100%;
        border-radius: 0;
        @include media('<=phone') {
            display: none;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .buttons{
        background: base.getColor("buttonBg");
        font-size: base.getTypography("normal");
        color:#FFF;
        width: 150px;
        height: 50px;
        border-radius: 40px;
        text-transform: capitalize;
        transition: 0.7s;
        &:hover{
            background: base.getColor("hoverColor");
        }
        @include media('<=phone') {
            width: 130px;
            height: 40px;
            font-size: base.getTypography("small");
        }
        
    }
}