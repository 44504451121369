@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.pageContent{
    margin-top: 65px;
    .pageBox{
        display: flow-root;
    }
    @include media('<=phone') {
        margin: 0;
    }
    ul{
        list-style: none;
        @include media('<=phone') {
            margin: 0;
            padding: 0;
        }
        li{
            line-height: 23px;
            margin-bottom: 10px;
            &::before{
                content: ' ';
                background: url("../../../public/document/images/listBg.png") no-repeat;
                background-size: 100%;
                margin-right: 16px;
                display: block;
                float: left;
                width: 18px;
                height: 18px;
            }
        }
        
    }
    img{
        padding: 0px 30px 30px 0px;
        max-width: 445px;
        float: left;
        margin-top: 50px;
        @include media('<=phone') {
            margin: 0;
            padding: 0;
        }
    }
    h1{
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("bold");
        color: base.getColor("color");
        margin: 50px 0 16px 0;
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }

    }
    p{
        font-size: base.getTypography("paragraph");
        font-family: base.getFontFamily("regular");
        color:base.getColor("color");
        padding-bottom: 16px;
        line-height: 23px;
        strong {
                font-family: base.getFontFamily("bold");
            }
    }
}