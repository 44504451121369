@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.contact{
    padding-top: 50px;
    h2{
        border-bottom: 1px solid base.getColor("color");
        margin-bottom: 15px;
        @include media('<=phone') {
            font-size: 55px;
        }
    }
    span{
        color:#2B65F6;
        font-family: base.getFontFamily("bold");
        font-size: base.getTypography("paragraph");
    }
    .contactTitle{
        color:#09294F; 
        font-family: base.getFontFamily("bold");
        padding: 5rem 2rem;
        text-align: center;
        cursor: pointer;
    }
    .card{
        border:0; 
        box-shadow: none;
    }
}