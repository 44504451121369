@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.affiliates{
    margin-top: 100px;
    .affGrid{
        margin-bottom: 50px;
        padding: 16px;
        .affCard {
            padding: 16px;
            overflow: visible;
            border: 1px solid #E8E8E8;
            .image {
                margin-top: -40px;
                background-color: #FFF;
                padding: 0 16px;
                border-radius: 15px;
                text-align: center;
                position: relative;
                img {
                    object-fit: none;
                    height: 62px;
                }
            }
            .affCardContent{
                padding: 0;
                margin-top:25px;
                h6{
                    font-size: base.getTypography("paragraph");
                    color: base.getColor("color");
                    font-family: base.getFontFamily("bold");
                    height: 80px;
                }
            }
        }
    }


}