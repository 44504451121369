@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.footerHome{
    background: base.getColor("contentBg");
    margin-top: 100px;
    padding-top: 0px;
    @include media("<=phone") {
        margin: 0;
        padding: 0;
        font-size: base.getMTypography("mfooterTitle");
    }
    .top{
        padding-bottom: 50px;
        border-bottom:1px solid #ffffff91;
        box-sizing: border-box;
        @include media("<=phone") {
            display: none;
        }
        .detailList{
            @include media("<=tablet") {
                text-align: center;
            }
            h3{
                font-size: base.getTypography("footerTitle");
                color: #FFF;
                font-family: base.getFontFamily("bold");
                padding-top: 10px;
                text-align: left;
                margin-top: 2rem;
            }
            ul{
                li{
                    margin: 0;
                    padding: 5px 0px;
                    a{
                        color: #ffffffa1;
                        text-decoration: none;
                        font-size: base.getTypography("paragraph");
                        transition: 0.5s;

                        &:hover{
                            color: base.getColor("color");
                        }
                    }
                }
            }
            .dgpaysLogo{
                max-width: 160px;
                margin-bottom: 30px;
                @include media("<=tablet") {
                    margin: 0 auto 30px auto;
                }
            }
            .socialMedia{
                margin-top: 30px;
            }
            span{
                font-family: base.getFontFamily("regular");
                color: #FFF;
                display: block;
                padding-right: 30px;
            }
            i{
                margin: 0;
                padding: 0px 15px 0px 0px;
                .iconButton {
                    width: 30px;
                    height: 30px;
            
                }
            }
        }
    }

    .bottom{
        @include media("<=phone") {
            display: none !important;
        }
        .contactList{
            padding-top: 50px;
            li{
                padding-top: 15px;
                padding-bottom: 15px;
                
                .footerContent{
                    margin-left: 15px;
                }
            }
                .contentText {
                    padding: 0 15px;

                    @include media("<=tablet") {
                        padding: 0;
                    }
                }
                .contentText2 {
                    padding: 5px 15px;
                    @include media("<=tablet") {
                        padding: 0;
                    }
                }
            h3{
                font-size: base.getTypography("footerTitle");
                color: #FFF;
                font-family: base.getFontFamily("extrabold");
            }
            h4{
                font-size: base.getTypography("normal");
                font-family: base.getFontFamily("extrabold");
                color: #FFF;
            }
            p{
                font-size: base.getTypography("small");
                font-family: base.getFontFamily("regular");
                color: #FFF;
                margin-bottom: 30px;
            }
            span{
                font-size: base.getTypography("normal");
                color: #FFF;
            }
            svg{
                color:#FFF;
                border:1px solid #FFF;
                border-radius: 20px;
                padding: 7px;
            }
        }
    }
    .copyright{
        text-align: center;
        border-top:1px solid #ffffff91;
        padding: 20px 0px;
        font-size: base.getTypography("paragraph");
        color: #FFF;
        font-family: base.getFontFamily("bold");
        ul{
            margin:0;
            li{
                display: inline-flex;
                padding: 0px 15px;
                border-right:0;
                &:not(:last-child){
                    border-right: 1px solid #FFF;
                }
                a{color: #FFF;}
            }
        }
        
        @include media("<=phone") {
            font-size: base.getMTypography("mparagraph");
        }
    }
}