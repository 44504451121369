@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.title{
    background: base.getColor("contentBg");
    height: 330px;
    @include media('<=phone') {
        height: 200px;
    }
    h1{
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("bold");
        color: #FFF;
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }
}