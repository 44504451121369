@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.affiliates{
    margin-top: 100px;
    .affGrid{
        margin-bottom: 50px;
        padding: 16px;
        a{
            text-decoration: none;
        }
        
        .affCard {
            padding: 16px;
            overflow: visible;
            border: 1px solid #E8E8E8;
            box-shadow: none;
            border-radius: 10px;
            .image {
                margin-top: -40px;
                background-color: #FFF;
                padding: 0 16px;
                border-radius: 15px;
                text-align: center;
                position: relative;
                overflow: hidden;
                height: 6em;
                img {
                    object-fit: contain;
                    height: 90%;
                }
            }
            .affCardContent{
                padding: 0;
                h6{
                    font-size: base.getTypography("paragraph");
                    color: base.getColor("color");
                    font-family: base.getFontFamily("bold");
                    height: 50px;
                }
            }
        }
    }


}