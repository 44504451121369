@use '../../../../styles/typography.scss' as base;
@import '../../../../styles/breakpoints.scss';

.newHome{    
    .newList{
        padding: 15px;
        p{
            font-size: base.getTypography("normal") !important;
            color: #546579 !important;
            font-family: base.getFontFamily("regular") !important;
            font-weight: 100 !important;
        }
        strong{font-weight: 100 !important;}

        h5{
            color: base.getColor("color");
            font-family: base.getFontFamily("extrabold");
            font-size: base.getTypography("paragraph");
            display: -webkit-box;
            -webkit-line-clamp: 2;/* İndirilecek satır sayısı */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
            max-height: 3em;
            min-height: 3em;
        }
        .cardContent{
            height: 142px;
            overflow: hidden;
        }
        .iconButton {
            border: 2px solid base.getColor("color");
            border-radius: 35px;
            color: base.getColor("color");
            width: 25px;
            height: 25px;
    
            svg {
                font-size: 15px;
            }
        }
    }
    h2 {

        font-size: base.getTypography("title");
        text-align: center;
        color: base.getColor("color");
        font-family: base.getFontFamily("bold");
        padding-bottom: 60px;
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }
    
        .sliderMobile {
            position: relative;
            overflow: hidden;
    
            img {
                width: 100%;
                margin: 0;
            }
    
            .sliderPrev,
            .sliderNext {
                position: absolute;
                display: block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                font-size: 0px;
                text-align: center;
                border-radius: 30px;
                cursor: pointer;
                top: 50%;
                transform: translate(0, -50%);
                padding: 0;
                border: none;
                outline: none;
                background-color: #fff9;
    
                svg {
                    width: 15px;
                    height: 30px;
                    text-align: center;
                }
            }
    
            .sliderPrev {
                left: 0px;
                z-index: 99;
    
                &:hover {
                    background-color: #FFF;
                    transition: 0.3s;
                }
    
                svg {
                    margin-left: 3px;
                }
            }
    
            .sliderNext {
                right: 0px;
                z-index: 99;
    
                &:hover {
                    background-color: #FFF;
                    transition: 0.3s;
                }
            }
    
        }
}