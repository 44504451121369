@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.representation {
    margin-top: 100px;

    .representationGrid {
        margin-bottom: 50px;
        padding: 16px;

        a {
            text-decoration: none;
        }

        .representationCard {
            padding: 0;
            overflow: visible;
            box-shadow: none;

            .image {
                margin-top: -40px;
                background-color: #FFF;
                padding: 0 16px;
                border-radius: 15px;
                text-align: center;
                position: relative;
                overflow: hidden;
                height: 6em;

                img {
                    object-fit: contain;
                    height: 90%;
                }
            }

            .representationCardContent {
                padding: 0;

                h6 {
                    font-size: base.getTypography("paragraph");
                    color: base.getColor("color");
                    font-family: base.getFontFamily("bold");
                }
            }
        }
    }


}