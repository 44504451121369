@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.news {
    .newList {
        padding: 15px;

        p {
            font-size: base.getTypography("normal") !important;
            color: #546579 !important;
            font-family: base.getFontFamily("regular") !important;
            font-weight: 100 !important;
        }

        strong {
            font-weight: 100 !important;
        }

        h5 {
            color: base.getColor("color");
            font-family: base.getFontFamily("extrabold");
            font-size: base.getTypography("paragraph");
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* İndirilecek satır sayısı */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
            max-height: 3em;
            min-height: 3em;
        }

        .cardContent {
            height: 150px;
            overflow: hidden;
        }

        .iconButton {
            border: 2px solid base.getColor("color");
            border-radius: 35px;
            color: base.getColor("color");
            width: 25px;
            height: 25px;

            svg {
                font-size: 15px;
            }
        }
    }

    h2 {

        font-size: base.getTypography("title");
        text-align: center;
        color: base.getColor("color");
        font-family: base.getFontFamily("bold");
        padding-bottom: 60px;

        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }

}

.newsDetail{
    overflow: hidden;
    @include media('<=phone') {
        display: block !important;
        .title{
            margin: 0;
        }
    }
    img{border-radius: 30px;}

    h1{
        font-size: base.getTypography("title");
        color: base.getColor("color");
        font-family: base.getFontFamily("bold");
        margin-bottom: 30px;
        @include media('<=phone') {
            margin-top: 15px;
            font-size: base.getMTypography("mtitle");
        }
    }
    p{
        line-height: 30px; 
        font-size: base.getTypography("normal");
    }
    strong{
        color: base.getColor("color"); 
        font-family: base.getFontFamily("bold");
    }
    p:first-child{
        font-size: base.getTypography("pageTitle");
        font-family: base.getFontFamily("bold");
    }
}
.digerHaberler{
    background-color: #F3F8FF;
    padding: 50px 0;
    span{
        text-align: center; 
        color: #2B65F6;
        font-size: base.getTypography("paragraph");
        display: block;
        width: 100%;
        font-weight: bold;
        padding-bottom: 20px;
    }
}