body,
html {
    height: 100%;
    margin: 0;
}

.left-side {
    background-color: #fff;
    padding: 50px;
}

.right-side {
    background: url('./images/softPosStatusBg.jpeg') no-repeat center center;
    background-size: cover;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 150px;
}

body {
    padding-top: 0px;
}

.two-columns {
    display: flex;
}

.two-columns > * {
    width: 50%;
    padding: 1rem;
}
