@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.team{
    margin-top: 100px;
    h2{
        font-size: base.getTypography("title");
        font-family: base.getFontFamily("extrabold");
        color: base.getColor("color");
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }
    .card{
        border: 0;
        padding: 50px;
        @include media('<=phone') {
            padding: 0;
        }
        .link{
            text-decoration: none;
        }
        h6{
            font-size: base.getTypography("paragraph");
            font-family: base.getFontFamily("bold");
            color: base.getColor("color");
            padding-bottom: 16px;
            @include media('<=phone') {
                font-size: base.getMTypography("msmall");
                height: 40px;
                padding: 0;
            }
        }
        span{
            font-size: base.getTypography("normal");
            font-family: base.getFontFamily("normal");
            color: base.getColor("color");
            @include media('<=phone') {
                font-size: base.getMTypography("msmall");
                height: 50px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
