@use '../../../../styles/typography.scss' as base;
@import '../../../../styles/breakpoints.scss';

.answerHome{
    background: base.getColor("contentBg");
    padding-bottom: 90px;
    
    a{
        display: contents;
    }
    
    p{
        text-align: center;
        padding-top: 100px;
        padding-bottom: 15px;
        color:#FFF;
        font-size: base.getTypography("paragraph");
        font-family: base.getFontFamily("regular");
    }
    h2{
        font-size: base.getTypography("title");
        text-align: center;
        color:#FFF;
        font-family: base.getFontFamily("bold");
        padding-bottom: 15px;
        @include media('<=phone') {
            font-size: base.getMTypography("mtitle");
        }
    }
    .answerList{
        padding: 20px;
        .answers{
            border: 1px solid #FFF;
            border-radius: 10px;
            padding: 15px;
            position: relative;
            top: 0;
            transition: 0.2s;
            cursor: pointer;
            &:hover{
                box-shadow: 0px 0px 20px 3px #ddd;
                top: -10px;
                background: #2770f2;
            }
            h3{
                font-family: base.getFontFamily("bold");
                font-size: base.getTypography("listTitle");
                color: #FFF;
                padding-top: 35px;
            }
            button{
                margin-top: 20px;
                border: 1px solid #FFF;
                width: 20px;
                height: 20px;
                svg{
                    width: 12px;
                    color: #FFF;
                }
            }
        }
    }
.sliderMobile {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        margin: 0;
    }

    .sliderPrev,
    .sliderNext {
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 0px;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background-color: #fff9;

        svg {
            width: 15px;
            height: 30px;
            text-align: center;
        }
    }

    .sliderPrev {
        left: 0px;
        z-index: 99;

        &:hover {
            background-color: #FFF;
            transition: 0.3s;
        }

        svg {
            margin-left: 3px;
        }
    }

    .sliderNext {
        right: 0px;
        z-index: 99;

        &:hover {
            background-color: #FFF;
            transition: 0.3s;
        }
    }

}
}
