@use './typography.scss' as base;
@import "./breakpoints.scss";

body {
    font-family: base.getFontFamily("regular");
    font-size: base.getTypography("paragraph");
    color: #000;
    margin: 0;
    padding: 0;
    @include media('<=phone') {
        padding: 70px 0px 0px 0px;
    }
}

a {
    text-decoration: none;
    color: #000;
}

ul{
    list-style: none !important;
}

.sliderMobile {
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        margin: 0;
    }

    .sliderPrev,
    .sliderNext {
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-size: 0px;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background-color: #fff9;

        svg {
            width: 15px;
            height: 30px;
            text-align: center;
        }
    }

    .sliderPrev {
        left: 0px;
        z-index: 99;

        &:hover {
            background-color: #FFF;
            transition: 0.3s;
        }

        svg {
            margin-left: 3px;
        }
    }

    .sliderNext {
        right: 0px;
        z-index: 99;

        &:hover {
            background-color: #FFF;
            transition: 0.3s;
        }
    }

}
