@font-face {
    font-family: 'Bw Medium';
    src: url('BwModelica-Medium.woff2') format('woff2'), url('BwModelica-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Thin';
    src: url('BwModelica-Thin.woff2') format('woff2'), url('BwModelica-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Black';
    src: url('BwModelica-Black.woff2') format('woff2'), url('BwModelica-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Hairline';
    src: url('BwModelica-Hairline.woff2') format('woff2'), url('BwModelica-Hairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw ExtraBold';
    src: url('BwModelica-ExtraBold.woff2') format('woff2'), url('BwModelica-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Bold';
    src: url('BwModelica-Bold.woff2') format('woff2'), url('BwModelica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Regular';
    src: url('BwModelica-Regular.woff2') format('woff2'), url('BwModelica-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bw Light';
    src: url('BwModelica-Light.woff2') format('woff2'), url('BwModelica-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}