@use '../../styles/typography.scss' as base;
@import '../../styles/breakpoints.scss';

.navbar{
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
    z-index: 99;   
    ul.menuBar {
        margin: 0;
        padding: 0;
        position: relative;
        .lang {
            a {
                &:hover {
                    border-bottom: 3px solid #FFF !important;
                }
            }
        }
        li {
            list-style-type: none;
            float: left;
            margin: 0 15px;
            padding-top: 15px;
            cursor: pointer;

            ul.subMenu {
                display: none;
            }
            a {
                text-decoration: none;
                color: base.getColor("color");
                font-family: base.getFontFamily("bold");
                border-bottom: 3px solid #FFF;
                display: block;
                height: 40px;
                cursor: pointer;
            }
            &:hover a{
                color: base.getColor("hoverColor");
                transition: 0.5s;
                border-bottom: 3px solid base.getColor("hoverColor");
            }
            &:hover ul.subMenu {
                display: block;
                position: absolute;
                width: 275px;
                border:1px solid #ddd;
                border-radius: 10px;
                margin:0;
                padding: 0;
                li:nth-last-child(1){
                    a{
                        border-bottom: 0;
                    }
                }
                li{
                    background-color: #FFF;
                    display: block;
                    width: 100%;
                    margin:0;
                    padding: 0;
                    a{
                        height: auto;
                        border-bottom: 1px solid #f1f1f1;
                        padding: 15px;
                        color: base.getColor("color");
                        &:hover{
                            color: base.getColor("hoverColor");
                        }
                    }
                }
            }
        }
    }
}
@include media("<tablet") {
    .navbar{
        max-width: 75% !important;
        li{
            margin:0 5px !important;
            ul.subMenu {
                li{
                    margin: 0 !important;
                }
            }
            a {
                font-size: 12px;
            }
        }
        .lang{
            font-size: 12px;
        }
    }
}

@include media('<phone') {
    .navbar {
        li{
            margin:0 !important;
        }
        ul.menuBar {
            position: fixed;
            top: 0;
            right: -100%;
            height: 100vh;
            width: 300px;
            transition: right 0.3s ease-in-out;
            background: linear-gradient(46deg, rgb(0, 232, 204) 7%, rgb(43, 101, 246) 100%);
            z-index: 99;
            .lang{
                background-color: #FFF;
                padding: 15px 0px;
            }
        }
        button{z-index: 999;}
        .closeButton {
            display: none;
        }
    }
    .openMenu{
        ul.menuBar{
            right: 0;
            padding: 60px 0px;
            li{
                width: 100%;
                margin: 0;
                padding: 0;
                float: left;
                border-bottom: 1px solid #eee;
                a {
                    color: #FFF;
                    padding: 10px;
                    border: 0;
                    margin: 0;
                    height: auto;
                }
            }
            li.liMenuBar{
                width: 100%;
                margin: 0;
                padding: 0;
                float: left;
                border-bottom: 1px solid #eee;
                
                a{
                    color: #FFF;
                    padding: 10px;
                    border: 0;
                    margin: 0;
                    height: auto;
                }
                ul.subMenu {
                    display: none;
                }
            }
            li.openSubMenu {
                ul.subMenu{
                    display: block;
                    position: relative;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    float: left;
                    border: 0;
                    border-radius: 0;
                    li{
                        width: 100%;
                        margin: 0;
                        background-color: #FFF;
                        
                        a{
                            font-size: 14px;
                            font-family: base.getFontFamily("regular");
                            border-bottom: 1px solid #f1f1f1;
                            padding: 15px;
                            color: #09294F;
                        }
                    }
                }
            }
        }
        .closeButton{display: block; color: #FFF;}
        .menuButton {display: none;}
    }

}