@use '../../styles/typography.scss' as base;
@import "../../styles/breakpoints.scss";

.TeamDetail{
    position: relative;
    padding-top: 2rem;
    z-index: 1;
    &::before{
        content: ' ';
        background-color:#F3F8FF;
        width: 100%;
        height: 260px;
        display: block;
        z-index: -1;
        position: absolute;
        top: 0;
    }
    .teamBiography{
        padding: 0 2rem;
        .teamName {
            height: 250px;
        }
    
        .textDetail {
            font-size: base.getTypography("paragraph");
            color: base.getColor("color");
        }
    }
    h1{
        font-size: base.getTypography("pageTitle");
        font-family: base.getFontFamily("extrabold");
        color: base.getColor("color");
        padding-bottom: 1rem;
        @include media("<=tablet"){
            text-align: center;
        }
    }
    h2{
        font-size: base.getTypography("paragraph");
        font-family: base.getFontFamily("regular");
        color: base.getColor("color");
        @include media("<=tablet") {
            text-align: center;
        }
    }
    p{
        font-size: base.getTypography("paragraph");
        color: base.getColor("color");
        @include media("<=tablet") {
            text-align: center;
        }

    }
}