.slider{
    position: relative;
    overflow: hidden;
    img{
        width: 100%;
        margin:0;
    }    
    .sliderPrev, .sliderNext  {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 0px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    background-color: #fff9;
        svg{
            width: 15px;
            height: 30px;
            text-align: center;
        }
    }
    .sliderPrev {
        left: 25px;
        z-index: 99;
        &:hover{
            background-color: #FFF;
            transition: 0.3s;
        }
        svg{
            margin-left: 3px;
        }
    }
    .sliderNext {
        right: 25px;
        z-index: 99;
        &:hover {
            background-color: #FFF;
            transition: 0.3s;
        }
    }
    
}
.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}